.my-alerts{
    .alert-info{
        width: 100%;
        margin-bottom: 1.1em;
        font-size: 13px;
        border: solid;
        border-width: 0 0 1px 0;
        border-color: #f1f0f0;
        padding-bottom: 6px;

        .alert-status{
            
            &.unread{
                span{
                    font-weight: 600;
                    color: #f5222e!important;
                }
            }

            &.readed{
                span{
                    font-weight: 600;                    
                    color: #36a51a;
                }    
            }
        }

        .alert-date{
            font-weight: 600;
            color: #a1a1c2 !important;
            text-align: right;
            padding-right: 1.5em
        }

        .alert-title{
            font-size: 12px;
        }

        .alert-btn-accept{
            padding-top: 10px;
            text-align: right;
            padding-right: 1.5em;

            .ant-typography-disabled{
                cursor: pointer;
                font-size: 12px;
            }
        }
    }
}