.routines{

    @media screen and (max-width: 576px){
        .ant-list-vertical .ant-list-item {
            -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
        }
    }

   .routine-list{



       .routine-item{

            .ant-list-item-extra{
                min-width: 40%;
            }

           .actions{
           }

           .avatar{}


           .routine-header{
                h4{
                    margin-bottom: 0;
                }
                
                .title{

                    padding: 0;
                    margin: 0;
                }

                
                .author{
                    padding: 0;
                    margin: 0;
                }

                .resume{
                    padding: 0;
                    margin: 0;
                }
           }
       }
   }
}