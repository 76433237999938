$border-radius: 15px;

.terms-and-conditions{
    .contain{
        display: inline-block;
        padding-bottom: 10px;
        width: 100%;
    }
    .btn-terms-and-conditions-readed{
        display: inline-block;
        clear: both;
        width: 100%;
    }
}

.checkout-component-V3{
    padding-bottom: 100px;

    .show-termns-and-conditions{
        color: black;
        text-decoration: dashed underline;
        text-underline-position: under;
    }

    .refresh-button{
        text-align: right;
    }

    .product-list{
        display: flex;
        height: auto;

        .product-info{
            margin: 0 5px 10px 5px;
            display: block;
            width: 100%;
            border-radius: $border-radius;

            &.selected{
                .selected-icon{
                    position: absolute;
                    right: -3px;
                    top: -8px;
                    border: solid 1px;
                    border-radius: 50%;
                    color: white;
                    padding: 4px;
                    width: 30px;
                    height: 30px;
                    text-align: center;

                    &.OnePay{
                        background: rgb(206,27,232);
                        background: linear-gradient(135deg, rgba(206,27,232,1) 0%, rgba(160,75,195,1) 39%, rgba(88,6,193,1) 100%);
                        box-shadow: 0px 12px 12px -9px rgba(88,6,193,1);
                    }
    
                    &.PayPerUse{
                        background: rgb(27,232,111);
                        background: linear-gradient(135deg, rgba(27,232,111,1) 0%, rgba(75,195,126,1) 29%, rgba(28,75,61,1) 100%);
                        box-shadow: 0px 12px 12px -9px rgba(27,232,111,1);
                    }
    
                    &.Subscription{
                        background: rgb(127,192,255);
                        background: linear-gradient(135deg, rgba(127,192,255,1) 0%, rgba(54,156,255,1) 33%, rgba(2,103,201,1) 100%);
                        box-shadow: 0px 12px 12px -9px rgba(127,192,255,1);
                    }
                }
            }

            .product-type{
                border-bottom: solid 1px transparent;
                height: 120px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: $border-radius $border-radius 0 0;
                
                .availability{
                    color: white;
                }

                &.OnePay{
                    background: rgb(206,27,232);
                    background: linear-gradient(135deg, rgba(206,27,232,1) 0%, rgba(160,75,195,1) 39%, rgba(88,6,193,1) 100%);
                    box-shadow: 0px 12px 12px -9px rgba(88,6,193,1);
                }

                &.PayPerUse{
                    background: rgb(27,232,111);
                    background: linear-gradient(135deg, rgba(27,232,111,1) 0%, rgba(75,195,126,1) 29%, rgba(28,75,61,1) 100%);
                    box-shadow: 0px 12px 12px -9px rgba(27,232,111,1);
                }

                &.Subscription{
                    background: rgb(127,192,255);
                    background: linear-gradient(135deg, rgba(127,192,255,1) 0%, rgba(54,156,255,1) 33%, rgba(2,103,201,1) 100%);
                    box-shadow: 0px 12px 12px -9px rgba(127,192,255,1);
                }

                .icon{
                    font-size: 3em;
                    color: rgba(255,255,255,.6);
                }

                .price{
                    color: white;

                    .before-discount{
                        display: block;
                        font-size: 14px;
                        text-align: center;
                        text-decoration: line-through;
                    }
                }
                
            }

            .product-body{
                border: solid #cfcfcf6c;
                border-width: 0 1px 0 1px;
                padding: 20px 10px 0 10px;
                
                .product-name{
                    font-weight: 600;
                    text-align: center;
                    text-decoration: underline dashed;
                    text-underline-position: under;
                    min-height: 3em;
                }

                .product-description{
                    text-align: center;
                    padding: 15px 0 5px 0;
                    height: 7em;
                    min-height: 7em;
                }
    
                
            }

            .features{
                border: solid #cfcfcf6c;
                border-width: 0 1px 0 1px;
                padding: 0 10px 0 10px;
                height: 250px;

                .features-title{
                    width: 100%;
                    text-align: center;
                    font-weight: 600;
                    text-decoration: underline dashed;
                    text-underline-position: under;
                }

                ul{
                    overflow: auto;
                    padding: 0 0 5px 0;
                    margin: 0;
                    li{
                        &:nth-child(even){
                            background: rgba(0,0,0,.03)
                        }

                        list-style: none;
                        padding: 7px;

                        .feature-check{
                            color: green;
                        }
                    }
                }
            }

            .select-product{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                border-radius: 0 0 $border-radius $border-radius;
                &.OnePay{
                    background: rgb(206,27,232);
                    background: linear-gradient(135deg, rgba(206,27,232,1) 0%, rgba(160,75,195,1) 39%, rgba(88,6,193,1) 100%);
                    box-shadow: 0px -12px 12px -9px rgba(88,6,193,1);
                }

                &.PayPerUse{
                    background: rgb(27,232,111);
                    background: linear-gradient(135deg, rgba(27,232,111,1) 0%, rgba(75,195,126,1) 29%, rgba(28,75,61,1) 100%);
                    box-shadow: 0px -12px 12px -9px rgba(27,232,111,1);
                }

                &.Subscription{
                    background: rgb(127,192,255);
                    background: linear-gradient(135deg, rgba(127,192,255,1) 0%, rgba(54,156,255,1) 33%, rgba(2,103,201,1) 100%);
                    box-shadow: 0px -12px 12px -9px rgba(127,192,255,1);
                }

                button{
                    color: white;
                    border: solid 1px white;
                }
            }

            
        }
    }

    .payment-resume{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;

        .ant-slider-track{
            background-color: black !important;
        }

        .initial-payment-advise{
            font-size: 1.2em;
        }

        .list-title{
            list-style: none;
            text-underline-position: under;
            margin-bottom: 5px;
        }

        .discount-info{
            list-style: none;
        }


        background-color: #4b7cf3!important;
        min-height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px;
        color: white;
        border-radius: $border-radius;

        .reduced-price{
            margin:0 !important;
            font-size: 3rem;
            font-weight: bold;
            color: white;
        }

        .price{
            margin-top: 0px !important;
        }

        .old-price{
            text-decoration: line-through;
        }

        .initial-payment{
            margin-top: 15px;
        }

        .discounts-list{
            padding: 0;
            .discount-info{
                small{
                    margin-right: 5px;
    
                    &.discount-amount{
                        font-size: 15px;
                        color: #36bd79;
                    }
                }
            }
        }

        

        .steps-selector{
            color: white;
            text-align: center;
        }

        .payment-buttons{

            .make-payment{
                background-color: #36bd79;
                border-color: #36bd79;
                color: white;
                margin-top: 10px;
                &:hover{
                    background-color: #2fa76b;
                    border-color: #36bd79;
                    color: white;
                }

                &:disabled{
                    border-color: #989899;
                    background-color: #ededef;
                    color: #989899;
                }
            }

            .particular-conditions{

                .title{
                    font-size: 1em;
                    text-decoration: underline dashed;
                    text-underline-position: under;
                }

                textarea{
                    margin-bottom: 5px;
                }
            }
        }
    }
}