.checkout-component-v2{

    .select-first{
        padding-top: 10px;
        text-align: center;
        .arrow{
            font-size: 3em;
        }

        .message{
            font-size: 1.2em;
        }
    }
    
    .refresh-button{
        text-align: right;
    }

    .product-list{
        .product-info-mobile{
            padding: 5px;
            margin-bottom: 10px;

            .product-type{
                text-align: center;
                padding-left: 10px;
                .icon{
                    color: white;
                    font-size: 2rem;
                }
                padding-bottom: 3px;
            }

            .product-name{
                text-align: center;
                padding-bottom: 3px;
                color: white;

                .title{
                    color: white;
                    padding-top: 8px !important;
                }
            }

            .button-info{
                text-align: center;
                width: 100%;
                min-height: 70px;
            }
        }

        .product-info{
            border: solid 1px #ededef;
            border-radius: 3px;
            box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);
            padding: 5px;
            margin-bottom: 10px;
            min-height: 230px;

            .product-actions{
                
                width: 150px;
                position: absolute;
                bottom: 20px;
                left:0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            
            

            .product-type{
                text-align: center;
                padding-left: 10px;
                .icon{
                    color: #595c97;
                    font-size: 2rem;
                }
                padding-bottom: 3px;
            }

            .product-name{
                text-align: center;
                padding-bottom: 3px;
            }


            &.selected{
                box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.404), 0 3px 6px 0 rgba(0, 0, 0, 0.192);

                border: solid 1px #40a9ff;
                .check-product{
                    position: absolute;
                    right: -3px;
                    top: -8px;
                    border: solid 1px;
                    border-radius: 50%;
                    background-color: #40a9ff;
                    color: white;
                    padding: 4px;
                }
            }

            
        }
    }

    .selected-product-info{
        .available-date{
            text-align: right;
            text-decoration: underline dashed;
            text-underline-position: under;
        }

        .icon{
            color: #595c97;
            font-size: 4rem;
            position: absolute;
            left: 10px;
        }

        .body-info{
            padding-left: 80px;
            .feature{
                margin-bottom: 10px;
            }
        }

        .pricing-resume{
            text-align: center;

            ul{
                list-style: none;
                padding-left: 0;
            }
        }

        .payment-resume{

            .ant-slider-track{
                background-color: black !important;
            }

            background-color: #4b7cf3!important;
            min-height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px;
            color: white;
            border-radius: 5px;

            .reduced-price{
                margin:0 !important;
                font-size: 3rem;
                font-weight: bold;
                color: white;
            }

            .price{
                margin-top: 0px !important;
            }

            .initial-payment{
                margin-top: 15px;
            }

            .discount-info{
                small{
                    margin-right: 5px;

                    &.discount-amount{
                        font-size: 15px;
                        color: #36bd79;
                    }
                }
            }

            .steps-selector{
                color: white;
                text-align: center;
            }

            .payment-buttons{
                text-align: right;

                .make-payment{
                    background-color: #36bd79;
                    border-color: #36bd79;
                    color: white;
                    &:hover{
                        background-color: #2fa76b;
                        border-color: #36bd79;
                        color: white;
                    }

                    &:disabled{
                        border-color: #989899;
                        background-color: #ededef;
                        color: #989899;
                    }
                }
            }
        }

    }
}