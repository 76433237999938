.remove-account-page {
  .form{
    p{
        text-align: center;
        font-size: 16px;
    }

    .email{
        font-size: 18px;
        border: solid 1px;
    }

    .go-login{
        width: 100%;
    }
  }
}