.athletes{

    .ant-card-meta-title{
        margin-bottom: 0px !important;

    }
    min-height: 290px;

    .ant-card-body{
        background: linear-gradient(to bottom left, #cc1a1a00 36.8%, #ff00000f 0) top/144% 100%, center/114% 26%;

        animation-name: background-card-move;
        animation-duration: 4s;
        animation-fill-mode: forwards;
    }

    .ant-typography{
        margin-bottom: 0 !important;
    }

    .athlete-name{
        margin-bottom: 8px !important;
    }

    .btn-add{
        position: absolute;
        right: 10px;
        margin-top: -35px
    }

    .btn-save-athlete{
        width: 100%;
        height: 22px;
        text-transform: uppercase;
    }

    .ant-drawer-body{
        padding: 10px;
    }

    .empty-athletes{
        min-height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .athlete-avatar-container{
        text-align: center;
        .avatar{
            /*border: 1px solid;*/
            border-color: #f0f0f0;
            border-radius: 3px;
            padding: 3px;
            width: auto;
            height: auto;
            max-width: 100px;
            /*max-height: 100px;*/
        }
    }

    
}

.btn-add-athlete{
    text-align: right;
    button{
        margin-left: 5px;
    }
}