

.checkout-navigator-balls{

    @media (min-width: 768px) {
       display: none;
       visibility: none;

    }

    @keyframes move-in{
        from { right: -100px }
        to { right: 20px }
    }

    animation-name: move-in;
    animation-duration: 1s;
    position: absolute;
    z-index: 10;
    list-style: none;
    right: 20px;
    padding: 0;


    li{
        color: black;
        height: 50px;
        width: 50px;
        background-color: white;
        margin: 10px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 5px 14px rgba(0,0,0,.1);
        box-sizing: border-box;
        cursor: pointer;
        font-size: 1.6em;
        transition: all .4s;

        &:hover{
            background-color: #4b7cf3;
            color: white;
            font-size: 2em;
            transition: all .4s;
        }

        &.h-one-pay{    
            background-color: rgb(89, 92, 151);
            color: white;
        }

        &.h-multi-pay{
            background-color: rgb(75, 124, 243);
            color: white;
        }

        &.h-pay-per-use{
            background-color: rgb(89, 92, 151);
            color: white;
        }

    }
}