$border-color: #cfcfcf;

.exercice-box{
    padding: 3px;
    overflow: auto;
    margin-bottom: 5px;
    margin-top: 5px;


    .title{
        padding: 3px !important;
        border: solid;
        border-color: $border-color;
        border-width: 0 0 1px 0;
        min-height: 3em;
        h3{
            color: white;
            float: left;
        }

        .extra{
            top: 0;
            right: 10px;
            padding: 3px;   
            float: right;

            .series{
                text-align: center;
                color: white;
                margin-left: 20px;
            }
            
            .duration{
                text-align: center;
                color: white;
            }
        }
    }

    .description{
        padding: 4px;
        color: white;
        text-align: justify;
    }

    .exercice-image{
        margin-left: auto;
        margin-right: auto;
        max-height: 500px;
    }
}