.routine-detail-header{
    .routine-actions{
        display: flex;
        align-items: center;
        justify-content: center;
        .routine-action-button{
            margin: 0 10px;
            font-size: 2em;
            cursor: pointer;
    
            &.star{
                &:hover{
                    color: rgb(255, 193, 7);
                }
            }
    
            &.like{
                &:hover{
                    color: rgb(24, 24, 208);
                }
            }
        }
    }
}