.rfea{
    #iframe-rfea{
        &.no-visible{
            visibility: hidden;
        }
    }
    .rfea-logo{
        max-width: 250px;
        margin-top:2em;
        margin-bottom: 1em;
    }

    display: flex;
    height: 100%;
    .rfea-iframe-container{
        display: flex;
        flex-direction: column;
        .iframe-rfea{
            width: 100%;
            border: none;
            overflow-y: auto;
            flex-grow: 1;
    
            .formulario_cabecera{
                background-color: blue !important;
            }
        }
    }
    
}