@import './keyframes.scss';
@import './step-by-step.tour.scss';

.enrollments, .history, .athletes, .events, .documents{
    margin-bottom: 25px;
}

.c-title{
    border: 1px solid;
    border-width: 0 0 1px 0;
    padding-bottom: 6px !important;
    border-color: #f0f0f0;
    font-size: 1.4em !important;

    .c-sub-title{
        padding-left: 37px;
        display: block;
        font-size: .5em;

        @media (max-width: 768px) {
            /*display: none;*/
        }
    }
}

/*Lo usamos para que las tarjetas queden alineadas con el mismo alto*/
.ant-card-body{
    min-height: 175px;

    @media (max-width: 768px){
        min-height: auto !important;
    }
}

.athlete-box-icon,
.enrollment-box-icon{
    font-size: 1.2em !important;
    padding: 0;
    margin: 0;

    &.active{
        color: green !important;
    }

    &.cancelled{
        color: red !important;
    }

    &.finished{
        color: rgb(22, 2, 135) !important;
    }

    &.pre{
        color: orange !important;

        &.remove{
            color: red !important;
        }
    }

    &.unpaid{
        color: #ff9800 !important;
    }
}

.block{
    display:block;
}


.athletes{
    .ant-card-actions{
        li{
            margin: 0;
            padding: 12px 0;
            background: white; 
        
            /* "to left" / "to right" - affects initial color */
            background: linear-gradient(to left, white 50%, #ff00000f 50%) right;
            background-size: 200%;
            transition: .5s ease-out;
        }
        li:hover{
            background-position: left;
        }
    }
}

.enrollments{
    .ant-card-actions{
        li{
            margin: 0;
            padding: 12px 0;
            background: white; 
        
            /* "to left" / "to right" - affects initial color */
            background: linear-gradient(to left, white 50%, #0029ff0f 50%) right;
            background-size: 200%;
            transition: .5s ease-out;
        }
        li:hover{
            background-position: left;
        }
    }
}



