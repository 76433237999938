.facebook-button-class{
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: #4867aa;
    border: none;
    border-radius: 3px;
    color:white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    width: 187.5px;
    height: 46px;
    cursor: pointer;

    i{
        margin-right: 12px;
        font-size: 1.4em;
    }
}