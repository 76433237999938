


@mixin styling($base-diameter, $base-vi, $base-vi-after, $face-visibility, $font-timer-size) {
    $watch-diameter: $base-diameter; // Con esto controlamos el ancho/alto del reloj
    $vi: $base-vi; // con esto el grosor de horas, minutos y segundos
    $vi-after: $base-vi-after; // con esto la distancia entre grosores

    .counter{
        @keyframes spin1 {
            0% {
                transform: rotate(225deg);
            }
            50% {
                transform: rotate(225deg);
            }
            100% {
                transform: rotate(405deg);
            }
        }
    
        @keyframes spin2 {
            0% {
                transform: rotate(225deg);
            }
            50% {
                transform: rotate(405deg);
            }
            100% {
                transform: rotate(405deg);
            }
        }
    
        .timer-group {
            height: $watch-diameter;
            margin: 0 auto;
            position: relative;
            width: $watch-diameter;
        }
    
        .timer {
            border-radius: 50%;
            height: 100px;
            overflow: hidden;
            position: absolute;
            width: 100px;
    
            &::after{
                background: #111; 
                border-radius: 50%;
                content: "";
                display: block;
                height: 80px;
                left: 10px;
                position: absolute;
                width: 80px;
                top: 10px;
            }
    
            .hand {
                float: left;
                height: 100%;
                overflow: hidden;
                position: relative;
                width: 50%;
    
                &:first-child {
                    transform: rotate(180deg);
    
                    span {
                        animation-name: spin1;
                    }
                }
    
                &:last-child span {
                    animation-name: spin2; 
                }
    
                span {
                    border: 50px solid rgba(0, 255, 255, .4);
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-radius: 50%;
                    display: block;
                    height: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: rotate(225deg);
                    width: 0;
    
                    animation-duration: 4s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                }
            }
    
            &.hour {
                background: rgba(0, 0, 0, .1);
                height: $watch-diameter;
                left: 0;
                width: $watch-diameter;
                top: 0;
    
                &::after {
                    height: $watch-diameter  - $vi-after;
                    left: $vi-after / 2;
                    width: $watch-diameter  - $vi-after;
                    top: $vi-after / 2;
                }
    
                .hand span {
                    animation-duration: 3600s;
                    border-top-color: rgba(255, 0, 255, .4);
                    border-right-color: rgba(255, 0, 255, .4);
                    border-width: $watch-diameter / 2;
                }
            }
        
            &.minute {
                background: rgba(0, 0, 0, .2);
                height: $watch-diameter - $vi;
                left: $vi / 2;
                width: $watch-diameter - $vi;
                top: $vi / 2;
    
                &::after {
                    height: $watch-diameter - $vi - $vi-after;
                    left: $vi-after / 2;
                    width: $watch-diameter - $vi - $vi-after;
                    top: $vi-after / 2;
                }
    
                .hand span {
                    animation-duration: 60s;
                    border-top-color: rgba(0, 255, 255, .4);
                    border-right-color: rgba(0, 255, 255, .4);
                    border-width: ($watch-diameter - $vi) / 2;
                }
            }
    
            &.second {
                background: rgba(0, 0, 0, .2);
                height: $watch-diameter - ( $vi * 2 );
                left: $vi;
                width: $watch-diameter - ( $vi * 2 );
                top: $vi;
    
                &::after {
                    height: $watch-diameter - ( $vi * 2 ) - 4px;
                    left: 2px;
                    width: $watch-diameter - ( $vi * 2 ) - 4px;
                    top: 2px;
                }
    
                .hand span {
                    animation-duration: 1s;
                    border-top-color: white;
                    border-right-color: white;
                    border-width: ($watch-diameter - ( $vi * 2 )) / 2;
                }
            }
        }
    
        .face {
            visibility: $face-visibility;
            background: rgba(0, 0, 0, .1);
            border-radius: 50%;
            height: $watch-diameter - ($vi * 2) - 4;
            left: 52px;
            padding: (($watch-diameter / 2) - 35px) 40px 0;
            position: absolute;
            width: $watch-diameter - ($vi * 2) - 4;
            text-align: center;
            top: 52px;
    
            h2, h5 {
                font-weight: 300; 
                color: white;
            }
    
            p {
                font-size: $font-timer-size;
                font-weight: 400;
                position: absolute;
                top:$vi;
                width: 100%;
                left: 0px;
                color: white;
            }
        
        }
    
        
    }
}

@media screen and (max-width: 500px) {
    @include styling($base-diameter: 200px, $base-vi: 40px, $base-vi-after: 20px, $face-visibility: hidden, $font-timer-size: small);
}
@media screen and (min-width: 501px) {
    @include styling($base-diameter: 400px, $base-vi: 50px, $base-vi-after: 40px, $face-visibility: visible, $font-timer-size: xxx-large);
}



