.subscription-payment-data{
    padding: .2em 1em;
        .detail-info-box-header{
        background-color: #fcfcfc;
        border: solid 1px #f0f0f0;
        border-radius: 3px;
        border-top-color: #0856a16e;
        border-top-width: 2px;
        box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);
        margin-top: 1.5em;
        margin-bottom: .5em;
        padding-bottom: 1em;

        .ant-divider-horizontal{
            margin: 10px 0 24px 0 !important;
        }

        .location-category-data{
            text-align:center;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding-top: 14px;
        }

        
        .separator{
            font-size: 2em;
            height: 55px;
            padding-left: .25em;
            padding-right: .25em;
        }
    }

    .payment-list-container{
        padding: 1em;
    }

    .detail-info-box{
        padding-left: 0px !important;
        padding-right: 0px !important;
        background-color: #fcfcfc;
        border: solid 1px #f0f0f0;
        padding: 1em;
        border-radius: 3px;
        border-top-color:#0856a16e;
        border-top-width: 2px;
        box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);
        margin-top: 1.5em;
        margin-bottom: .5em;

        .discount-apply{
            display:block;
            font-size: 12px;
            font-weight: normal;
            strong{
                font-weight: 700;
            }
        }

        @media (max-width: 1500px) and (min-width: 1199px) {
            min-width: 100% !important;
            margin-left: 0 !important;
        }

        .title{
            box-shadow: inset 0 -1px 0 #e6ebf1;
            padding: 12px;
        }

        span{
            display:block;
        }

        table{
            .table-header{
                font-weight: bold;
                min-width: 100px;
                padding-left: 2em;
            }

            .table-content{
                padding-left: 3em;
            }
        }
    }
}