.enrollments{
    min-height: 290px;

    .free-enrollment{
        position: absolute;
        right: 5px;
        font-size: 1.5em;
        color: #FFC107;
        top: 5px;
    }


    .ant-card-body{
        animation-name: background-card-move;
        animation-duration: 4s;
        background: linear-gradient(to bottom left, #cc1a1a00 36.8%, #0029ff0f 0%) top/144% 100%, center/114% 26%;
        animation-fill-mode: forwards;
    }


    .season-selector{
        position: absolute;
        right: 145px;
        top: 10px;
        width: 180px;

        @media(max-width: 500px){
            position: inherit;
            right: 0;
            margin-top: 0;
            width: 100%;
        }
    }

    .btn-add{
        position: absolute;
        right: 60px;
        top: 10px;
        width: 80px;
    }

    .btn-reload{
        position: absolute;
        right: 10px;
        top: 10px; 
    }

    .btn-save-enrollment{
        width: 100%;
        height: 22px;
        text-transform: uppercase;
    }

    .ant-drawer-body{
        padding: 10px;
    }

    .empty-enrollments{
        min-height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .c-title{
        margin-bottom: 0 !important;
    }

    
}