.documents{
    .ant-card-body{
        animation-name: background-card-move;
        animation-duration: 4s;
        background: linear-gradient(to bottom left, #cc1a1a00 36.8%, #0029ff0f 0%) top/144% 100%, center/114% 26%;
        animation-fill-mode: forwards;
    }

    .actions{
        display: flex;
        justify-content: center;
        height: 100%;
        button{
            margin: 5px;
        }
    }

    .btn-reload{
        position: absolute;
        right: 10px;
        top: 10px; 
    }
}