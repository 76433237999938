
.stripe-credit-card-form{
    button{
        width: 100%;
    }
}

.credit-card-warning{
    margin-top: 1em;
}

.stripe-credit-card-info{
    margin-bottom: 2em;
}

.StripeElement{
    max-width: 100% !important;
}

.button-actions{
    border: solid 1px;
}