
.stripe-bank-account-form{
    button{
        width: 100%;
    }
}

.bank-account-warning{
    margin-top: 1em;
}

.stripe-bank-account-info{
    margin-bottom: 2em;
}