.checkout{

    .discount-description,
    .discount-previous-amount{
        display: block;
    }

    .safety-certificate{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5em;
        margin-bottom: 1em;

        .shield{
            font-size: 1.7em;
            margin-right: 10px;
        }
    }

    .feature{
        display:block;
    }

    .terms-and-conditions{
        .col-accept-terms{
            text-align: center;
        }
    }

    .ant-slider:hover .ant-slider-track{
        background-color: white;
    }


    .ant-slider .ant-slider-track{
        background-color: #242525;
    }

    .cart-option{
        text-align: center;
        padding: 1em 3em;
        border: solid 1px;
        border-radius: 6px;
        box-shadow: 0 4px 10px 0 rgba(20,19,34,.03), 0 0 10px 0 rgba(20,19,34,.02);
        border-color:#e4e9f0;
        margin-bottom: 20px;


        .btn-pay{
            width: 100%;
            max-width: 100%;
            margin: 2em 0 0 0;
            height: auto;

            &.danger{
                border-color: red;
                color: red;

                &:hover{
                    background-color: rgba(255, 0,0,.1);
                }
            }

            @media (max-width: 768px) {
                position: initial;
                width: 100%;
                max-width: 100%;

            }
        }

        .ant-list-item{
            text-align: center !important;
            display: block !important;
            border: none !important;
            padding: 2px 14px !important;
        }

        .price{
            font-size: 3rem;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 0;
        }

        .icon{
            color: #595c97;
            font-size: 4.5rem;
        }

        .sub-title{
            margin-bottom:2em !important;
            display: block;
        }

        .title{
            padding-top: 5px;
            margin-bottom: 0;
        }

        &.one-pay-mode{
        
        }
    
        &.multiple-pay-mode{
            background-color: #4b7cf3!important;

            .icon, .price, .ant-list-item, .title, .sub-title, .ant-list-header{
                color: white !important;
            }

            .btn-pay{
                width: 100%;
                margin: 2em 0 0 0;
                background-color: white !important;
                color: #4b7cf3;

                &:disabled{
                    color: rgba(0, 0, 0, 0.25);
                }
            }
        }
    
        &.pay-per-use{
            background-color: #f2f4f8!important;
        }



    }

    
    
    
    .resume{

        @media (max-width: 768px) {
            padding: 0 !important;
        }

        .product-name{
            margin: 0 !important;
        }

        .product-price{
            width: 100%;
            margin: 0 !important;
        }

        .product-image{
            width: 100%;
            background-color: #2222222e;
            border-radius: 5px;
            padding: 2em;
            margin: 1.5em 0;
        }

        
    }

        .partners{
            width: 100%;
            margin-bottom: 1em;

            background-color: #2222222e;
            display: flex;
            align-items: center;
            justify-content: center;

            .partner{
                max-width: 110px;
                margin: 10px;
                text-align: center;
                img{
                    max-width: 100%;
                }
    
            }
        }

    
}