.discount-alert{
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 255, 0, .1);
    box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .icon{
        font-size: 5em;
    }

    

    .body{
        margin-left: 10px;

        @media (max-width: 768px) {
            text-align:center;
            margin-left: 0px;

            
            ul{
                padding: 0;
            }
        }

        .refresh{
            margin-top: 10px;            
        }

        .description{
            display: block;
            font-size: 12px;
        }
    }
}